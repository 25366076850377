import React, { Component } from 'react';
import DateTimePicker from 'react-datetime-picker';
import LoadingSpinner from '../components/LoadingSpinner';
import { createReport, getAllClinics } from '../services/http/authService';
import dateFormat from 'dateformat';
import { isMobile } from 'react-device-detect';
import { errorToast } from '../constants/toasts';
import { ToastContainer } from 'react-toastify';

export default class Reports extends Component {
    state = {
        start_date: '',
        end_date: '',
        test_type: '',
        clinicId: '',
        isToday: true,
        clinics: [],
        clinicName: ''
    };

    componentDidMount() {
        // window.scrollTo(0, 0);
        this.getClinics();
    }

    getClinics = () => {
        this.setState({ isLoading: true });
        getAllClinics().then(this.onGetClinicsSuccess).catch(this.onPostFailed);
    }

    onGetClinicsSuccess = (res) => {
        const clinics = res.result;
        if (!clinics) {
            this.setState({ isLoading: false })
            return;
        }
        this.setState({ isLoading: false, clinics, clinicId: clinics[0].id, clinicName: clinics[0].full_name })
    }

    onSendClick = async (e) => {
        let { start_date, test_type, end_date, isToday } = this.state;

        if (test_type.length == 0 || (!isToday && end_date.length == 0 && start_date.length == 0)) {
            errorToast('Molimo popunite sva polja');
            return;
        }
        // window.scrollTo(0, 0);

        this.setState({ isLoading: true });
        createReport(this.state)
            .then((res) => {
                this.onPostSuccess(res.result);
            })
            .catch((err) => {
                this.onPostFailed(err.message);
            });
    };

    onPostSuccess = (res) => {
        let out = res.report.pdf_link;
        if (!isMobile) {
            window.open(out, '_blank', 'noopener,noreferrer');
        }
        else window.location.assign(out)
        // alert('Izvjestaj uspjesno kreiran. Izvjestaj mozete pronaci: \n' + out);

        this.setState({
            isLoading: false,
            error: '',
            start_date: '',
            end_date: '',
            test_type: '',
            isToday: true
        });
    };

    onPostFailed = (err) => {
        console.log(err);
        errorToast('GREŠKA: ' + err);
        this.setState({
            isLoading: false,
            error: err,
            start_date: '',
            end_date: '',
            test_type: '',
            isToday: true
        });
    };

    handleDateChange = (e, property) => {
        // this.setState({ [property]: e });
        const startDate = e;
        const endDate = new Date();
        endDate.setFullYear(e.getFullYear());
        endDate.setMonth(e.getMonth());
        endDate.setDate(e.getDate());
        endDate.setSeconds(59);
        endDate.setMinutes(59);
        endDate.setHours(23);
        this.setState({ start_date: startDate, end_date: endDate });
    };

    handleSelectTestType = (e) => {
        this.setState({ test_type: e.target.value });
    };

    handleSelectReportToday = (e) => {
        this.setState({ isToday: e.target.value == "true" });
    };

    handleDropdownChange(item) {
        let value = this.state.defaultValue;
        if (item.target != null && item.target.value != null) {
            value = item.target.value;
        }
        this.setState({ clinicId: value });
    }

    handleSelectChange(event) {
        const { clinics } = this.state;
        const newValue = event.target.value;
        const clinic = clinics.find((item) => item.full_name == newValue);
        if (!clinic) return;
        this.setState({ clinicName: newValue, clinicId: clinic.id });
    }

    renderSelect = () => {
        return (
            <select value={this.state.clinicName} onChange={this.handleSelectChange.bind(this)}>
                {this.state.clinics.map((clinic) => {
                    return <option value={clinic.full_name}>{clinic.full_name}</option>
                })}
            </select>
        )
    }

    render() {
        return (
            <div className="main-cont">
                {this.state.isLoading == true ? (
                    <div className="text-center" style={{ marginTop: '150px', marginBottom: '150px' }}>
                        <LoadingSpinner height={100} width={100} text="Kreiranje izvjestaja u toku. Molimo nemojte zatvarati ovaj prozor..." />
                    </div>
                ) : (
                    <main id="main" ref={this.racesRef}>
                        <ToastContainer
                            newestOnTop
                            limit={5}
                        />
                        <section id="featured" class="featured">
                            <div class="container">
                                <div class="section-title" data-aos="fade-up">
                                    <h2>Kreiranje Izvještaja</h2>
                                </div>
                                {this.state.error && <div className="error-text big">{'Greška: ' + this.state.error}</div>}
                                <div class="row">
                                    <div class="col-lg-3"> </div>
                                    <div class="col-lg-6">
                                        <div class="icon-box fivek">
                                            <div class="text-block text-center">{/* <h2>Bosanski jezik</h2> */}</div>
                                            <label for="first_name"><b>Odaberite korisnika *</b></label>
                                            <br></br>
                                            {/* <select defaultValue={this.state.clinicId} onChange={this.handleDropdownChange.bind(this)}>
                                                <option value="1">Sarajevo</option>
                                                <option value="2">Travnik</option>
                                                <option value="3">Travnik 2</option>
                                                <option value="4">Vitez</option>
                                                <option value="5">Busovaca</option>
                                                <option value="6">Novi Travnik</option>
                                            </select> */}
                                            {this.state.clinics && this.renderSelect()}
                                            <br></br>
                                            <br></br>
                                            <label>
                                                <b>Izvjestaj za danas *</b>
                                            </label>
                                            <div onChange={this.handleSelectReportToday}>
                                                <input type="radio" value="true" name="isToday" defaultChecked /> DA {'             '}
                                                <input type="radio" value="false" name="isToday" style={{ marginLeft: '20px' }} /> NE
                                            </div>
                                            <br></br>
                                            {this.state.isToday ? <div></div> :
                                                // <div>
                                                //     <label for="start_date">Datum od *</label>
                                                //     <br></br>
                                                //     <DateTimePicker onChange={(e) => this.handleDateChange(e, 'start_date')} value={this.state.start_date} format="dd/MM/y HH:mm" disableClock="true" />
                                                //     <br></br>
                                                //     <br></br>
                                                //     <label for="start_date">Datum do *</label>
                                                //     <br></br>
                                                //     <DateTimePicker
                                                //         onChange={(e) => this.handleDateChange(e, 'end_date')}
                                                //         value={this.state.end_date}
                                                //         format="dd/MM/y HH:mm"
                                                //         disableClock="true"
                                                //     />
                                                //     <br></br>
                                                //     <br></br>
                                                // </div>
                                                <div>
                                                    <label for="start_date">Odaberite datum *</label>
                                                    <br></br>
                                                    <DateTimePicker
                                                        onChange={(e) => this.handleDateChange(e, 'start_date')}
                                                        value={this.state.start_date}
                                                        format="dd/MM/y"
                                                        disableClock="true"
                                                    />
                                                    <br></br>
                                                    <br></br>
                                                </div>
                                            }
                                            <label>
                                                <b>Vrsta testa *</b>
                                            </label>
                                            <div onChange={this.handleSelectTestType}>
                                                <input type="radio" value="PCR" name="test_type" /> PCR {'             '}
                                                <input type="radio" value="ANTIGEN" name="test_type" style={{ marginLeft: '20px' }} /> ANTIGEN
                                                <input type="radio" value="ALL" name="test_type" style={{ marginLeft: '20px' }} /> SVI
                                            </div>
                                            <p style={{ color: '#d40404' }}>* Potrebna polja</p>
                                            <div class="text-center">
                                                <button class="btn btn-sm box-btn" onClick={this.onSendClick}>
                                                    Kreiraj
                                                </button>
                                            </div>
                                        </div>
                                        <br></br>
                                    </div>
                                    <div class="col-lg-3"> </div>
                                </div>
                            </div>
                        </section>

                    </main>
                )}

                {/* <Footer></Footer> */}
            </div>
        );
    }
}
