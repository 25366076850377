import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { getCurrentUser } from '../services/http/authService';
import DatePicker from 'react-date-picker';
import DateTimePicker from 'react-datetime-picker';
import dateFormat from 'dateformat';

export default class EditPatientModal extends Component {
  state = {
    newPatientProperties: {
      first_name: '',
      last_name: '',
      passport_num: '',
      email: '',
      phone: '',
      date_of_birth: '',
      date_of_test: '', // should this be allowed to be edited?
      test_maker: '',
      test_reason: '',
      test_type: '',
      is_positive: '',
      gender: '',
    }
  }

  handleSelectGender = (e) => {
    this.setState({
      newPatientProperties: {
        ...this.state.newPatientProperties,
        gender: e.target.value
      }
    });
  };

  handleSelectTestReason = (e) => {
    this.setState({
      newPatientProperties: {
        ...this.state.newPatientProperties,
        test_reason: e.target.value
      }
    });
  };

  handleDateChange = (e, property) => {
    this.setState({
      newPatientProperties: {
        ...this.state.newPatientProperties,
        [property]: e
      }
    });
  };

  handleInputChange = (e, property) => {
    this.setState({
      newPatientProperties: {
        ...this.state.newPatientProperties,
        [property]: e.target.value
      }
    });
  };

  evaluatePatient = () => {
    let newPatient = this.props.patient;
    Object.keys(this.state.newPatientProperties).forEach(element => {
      if (this.state.newPatientProperties[element].length != 0) {
        newPatient[element] = this.state.newPatientProperties[element];
      }
    });
    return newPatient;
  }

  formatPatientDate = (date) => {
    return new Date(date);
  }

  onChange(patient, is_positive, date) {
    const prefix = "Jeste li sigurni da je pacijent ";
    const infix = patient.first_name + " " + patient.last_name + " ";
    const suffix = is_positive == true ? "POZITIVAN?" : "NEGATIVAN?";
    if (window.confirm(prefix + infix + suffix)) {
      this.props.onSendClick(patient, is_positive, date);
    }
  }

  render() {
    return <div>
      {this.props.patient &&
        <div>
          ({
            getCurrentUser().lab_id ? <Modal show={this.props.isEditModalShown} onHide={this.props.hideEditModal}>
              <Modal.Header>
                <Modal.Title>Promijeniti rezultat pacijenta "{this.props.patient.first_name} {this.props.patient.last_name}" u {' '}
                  {this.props.patient.is_positive == true ? "NEGATIVAN" : "POZITIVAN"}?
                </Modal.Title>
              </Modal.Header>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.props.hideEditModal}>
                  NE
                </Button>
                <Button variant="primary" onClick={(e) => { this.onChange(this.props.patient, !this.props.patient.is_positive, dateFormat(this.props.patient.date_of_issuing_report_datetime, "dd/mm/yyyy/ HH:MM")) }}>
                  DA
                </Button>
              </Modal.Footer>
            </Modal> : <Modal show={this.props.isEditModalShown} onHide={this.props.hideEditModal}>
              <Modal.Header>
                <Modal.Title>{this.props.patient ? "Izmjena pacijenta: " + this.props.patient.first_name : "Odaberite pacijenta"}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <label for="first_name">Ime</label>
                <br></br>
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  style={{ width: '100%' }}
                  placeholder={this.props.patient.first_name}
                  onChange={(e) => this.handleInputChange(e, 'first_name')}
                />
                <br></br>
                <br></br>
                <label for="last_name">Prezime</label>
                <br></br>
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  style={{ width: '100%' }}
                  placeholder={this.props.patient.last_name}
                  onChange={(e) => this.handleInputChange(e, 'last_name')}
                />
                <br></br>
                <br></br>
                <label for="passport_num">Broj pasoša</label>
                <br></br>
                <input
                  type="text"
                  id="passport_num"
                  name="passport_num"
                  style={{ width: '100%' }}
                  placeholder={this.props.patient.passport_num}
                  onChange={(e) => this.handleInputChange(e, 'passport_num')}
                />
                <br></br>
                <br></br>
                <label for="email">Email</label>
                <br></br>
                <input
                  type="email"
                  id="email"
                  name="email"
                  style={{ width: '100%' }}
                  onChange={(e) => this.handleInputChange(e, 'email')}
                  placeholder={this.props.patient.email}
                />
                <br></br>
                <br></br>
                <label for="phone">Broj telefona</label>
                <br></br>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  style={{ width: '100%' }}
                  onChange={(e) => this.handleInputChange(e, 'phone')}
                  placeholder={this.props.patient.phone}
                />
                <br></br>
                <br></br>
                <label for="date_of_birth">Datum rođenja</label>
                <br></br>
                <DatePicker
                  onChange={(e) => this.handleDateChange(e, 'date_of_birth')}
                  value={this.state.newPatientProperties.date_of_birth.length == 0 ? this.formatPatientDate(this.props.patient.date_of_birth) : this.state.newPatientProperties.date_of_birth}
                  format="dd/MM/y"
                />
                <br></br>
                <br></br>
                <label for="date_of_test">Datum uzimanja uzorka</label>
                <br></br>
                <DateTimePicker
                  onChange={(e) => this.handleDateChange(e, 'date_of_test')}
                  value={this.state.newPatientProperties.date_of_test.length == 0 ? this.formatPatientDate(this.props.patient.date_of_test) : this.state.newPatientProperties.date_of_test}
                  format="dd/MM/y HH:mm"
                  disableClock="true"
                />
                <br></br>
                <br></br>
                <label>
                  <b>Spol (trenutno: {this.props.patient.gender == "Male" ? "Muško" : "Žensko"})</b>
                </label>
                <div onChange={this.handleSelectGender}>
                  <input type="radio" value="Male" name="gender" /> Muško {'             '}
                  <input type="radio" value="Female" name="gender" style={{ marginLeft: '20px' }} /> Žensko
                </div>
                <br></br>
                <br></br>
                {this.props.patient.test_type == "PCR" &&
                  <div>
                    <label>
                      <b>Razlog testiranja (trenutno: {this.props.patient.test_reason})</b>
                    </label>
                    <div onChange={this.handleSelectTestReason}>
                      <input type="radio" value="PUTOVANJE" name="test_reason" /> Putovanje {'             '}
                      <input type="radio" value="SIMPTOMI" name="test_reason" style={{ marginLeft: '20px' }} /> Simptomi {'             '}
                      <input type="radio" value="KONTAKT SA ZARAŽENOM OSOBOM" name="test_reason" style={{ marginLeft: '20px' }} /> Kontakt sa zaraženom osobom
                    </div>
                  </div>
                }

                <br></br>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.props.hideEditModal}>
                  Nazad
                </Button>
                <Button variant="primary" onClick={(e) => { this.props.onSendClick(e, this.evaluatePatient()) }}>
                  Dalje
                </Button>
              </Modal.Footer>
            </Modal>
          })</div>}
    </div>
  }


}