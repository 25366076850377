import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import CustomTable from '../components/CustomTable';
import EditPatientModal from '../components/EditPatientModal';
import LoadingSpinner from '../components/LoadingSpinner';
import PDFPreviewModal from '../components/PDFPreviewModal';
import PreviewPatientModal from '../components/PreviewPatientModal';
import { errorToast, successToast } from '../constants/toasts';
import { getAllClinics, getPendingLabUsers, getResolvedLabUsers, putUserTestResult } from '../services/http/authService';
import { getCurrentUser } from '../services/http/authService';

export default class Lab extends Component {

    PER_PAGE = 50;

    // 0 - pending
    // 1 - resolved
    state = {
        display: 0,
        users: [],
        usersDisplayed: [],
        isLoading: false,
        activePage: 1,
        sort_asc: false,
        last_sort: '',
        optionState: '',
        isEditModalShown: false,
        isPreviewModalShown: false,
        isPDFPreviewModalShown: false,
        pdfModalURL: null,
        clinics: [],
        user: null,
        modalPatient: null
    };

    componentDidMount() {
        // window.scrollTo(0, 0);
        const user = getCurrentUser();
        this.setState({ user });
        this.getClinics();
    }

    getClinics = () => {
        this.setState({ isLoading: true });
        getAllClinics().then(this.onGetClinicsSuccess).catch(this.onGetUsersError);
    }

    getPendingLabUsers = (clinicId) => {
        this.setState({ isLoading: true });
        getPendingLabUsers(clinicId).then(this.onGetUsersSuccess).catch(this.onGetUsersError);
    };

    getResolvedLabUsers = (clinicId) => {
        this.setState({ isLoading: true });
        getResolvedLabUsers(clinicId).then(this.onGetUsersSuccess).catch(this.onGetUsersError);
    };

    onGetUsersError = (err) => {
        this.setState({ isLoading: false });
        if (err && err.message) {
            if (err.message.includes('No news') || err.message.includes('Failed to fetch')) {
                return;
            }
        }
        errorToast(err.message);
    };

    getStartIndex = (pageNumber) => {
        return (pageNumber - 1) * this.PER_PAGE;
    }

    getEndIndex = (pageNumber) => {
        return pageNumber * this.PER_PAGE;
    }

    onGetClinicsSuccess = (res) => {
        const clinics = res.result;
        this.setState({ isLoading: false, clinics });
        if (clinics == null || clinics.length == 0) {
            errorToast("Problem u dobavljanju klinika");
            return;
        }
        this.setState({ optionState: clinics[0].full_name });
        this.getPendingLabUsers(clinics[0].id);
    }

    onGetUsersSuccess = (res) => {
        const usersArray = res.result.reverse();
        const pageNumber = this.state.activePage;
        const usersDisplayed = usersArray.slice(this.getStartIndex(pageNumber), this.getEndIndex(pageNumber))
        this.setState({ users: usersArray, usersDisplayed, isLoading: false });
    };

    updateUserTest = (user, isPositive, dateOfIssuingReport) => {
        this.setState({ isLoading: true, isEditModalShown: false });
        putUserTestResult(user.id, isPositive, dateOfIssuingReport).then(this.onPutSuccess).catch(this.onPutError);
    }

    onPutSuccess = (res) => {
        this.setState({ isLoading: false });
        successToast("Pacijent uspješno ažuriran");
        this.updateDisplay(this.state.display);
    };

    onPutError = (err) => {
        this.setState({ isLoading: false });
        if (err && err.message) {
            if (err.message.includes('No news') || err.message.includes('Failed to fetch')) {
                return;
            }
        }
        errorToast(err.message);
    };

    updateDisplay(display) {
        const clinic = this.state.clinics.find((item) => item.full_name == this.state.optionState);
        if (!clinic) return;
        if (display == 0) {
            this.getPendingLabUsers(clinic.id);
        } else if (display == 1) {
            this.getResolvedLabUsers(clinic.id);
        }
        this.setState({ display, activePage: 1 });
    }

    switchDisplay(targetDisplay) {
        if (targetDisplay != this.state.display) {
            this.updateDisplay(targetDisplay);
        }
    }

    handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState({ activePage: pageNumber, usersDisplayed: this.state.users.slice(this.getStartIndex(pageNumber), this.getEndIndex(pageNumber)) });
    }

    sortUsers = (e, by) => {
        e.preventDefault();
        const { usersDisplayed, last_sort, sort_asc } = this.state;
        let sorted;
        let sort_asc_state = sort_asc;
        if (by == last_sort) {
            sort_asc_state = !sort_asc_state;
        }
        sorted = usersDisplayed.sort((a, b) => {
            if (a[by] < b[by]) {
                return sort_asc_state ? -1 : 1;
            }
            if (a[by] > b[by]) {
                return sort_asc_state ? 1 : -1;
            }
            return 0;
        });
        this.setState({ usersDisplayed: sorted, last_sort: by, sort_asc: sort_asc_state });
    };

    handleSelectChange(event) {
        const { clinics, display } = this.state;
        const newValue = event.target.value;
        const clinic = clinics.find((item) => item.full_name == newValue);
        if (!clinic) return;
        this.setState({ optionState: newValue });
        if (display == 0) {
            this.getPendingLabUsers(clinic.id);
        } else if (display == 1) {
            this.getResolvedLabUsers(clinic.id);
        }
    }

    renderSelect = () => {
        return (
            <select value={this.state.optionState} style={{ marginLeft: "10px" }} onChange={this.handleSelectChange.bind(this)}>
                {this.state.clinics.map((clinic) => {
                    return <option value={clinic.full_name}>{clinic.full_name}</option>
                })}
            </select>
        )
    }

    handleEditClick = (e, patient) => {
        e.preventDefault();
        this.setState({ isEditModalShown: true, modalPatient: patient });
    }

    hideEditModal = () => {
        this.setState({ isEditModalShown: false, modalPatient: null });
    }

    handlePreviewClick = (e, patient) => {
        e.preventDefault();
        this.setState({ isPreviewModalShown: true, modalPatient: patient });
    }

    hidePreviewModal = () => {
        this.setState({ isPreviewModalShown: false, modalPatient: null });
    }

    handlePDFClick = (e, patient) => {
        e.preventDefault();
        this.setState({ isPDFPreviewModalShown: true, pdfModalURL: patient.pdf_link_primary });
    }
    
    hidePDFPreviewModal = () => {
        this.setState({ isPDFPreviewModalShown: false, pdfModalURL: null });
    }

    render() {
        const { display, users, usersDisplayed, activePage, clinics } = this.state;
        return (
            <div className="main-cont">
                {this.state.isLoading == true ? (
                    <div className="text-center" style={{ marginTop: '150px', marginBottom: '150px' }}>
                        <LoadingSpinner height={100} width={100} text="Učitavanje u toku. Molimo nemojte zatvarati ovaj prozor..." />
                    </div>
                ) : (
                    <main id="main" ref={this.racesRef}>
                        <br></br>
                        <br></br>
                        <br></br>
                        <ToastContainer
                            newestOnTop
                            limit={5}
                            />
                        <div class="section-title" data-aos="fade-up">
                            <h2>Laboratoriji</h2>
                        </div>
                        <div class="section-title" data-aos="fade-up">
                            <p style={{ color: 'rgb(113 113 113)', fontSize: "14px", marginTop: "-30px" }}>novododani pacijenti se pojavljuju na vrhu prve stranice</p>
                        </div>
                        <section id="featured" class="featured" style={{ paddingTop: "0px" }}>
                            <div class="container-fluid">
                                <div class="row"></div>
                                <div class="row">
                                    <div class="col-md-4"></div>
                                    <div class="col-md-4 text-center">
                                        <div style={{ display: "inline-block" }}>
                                            Odaberite kliniku
                                        </div>
                                        {clinics && this.renderSelect()}
                                    </div>
                                    <div class="col-md-4"></div>
                                </div>
                                <br></br>
                                <hr></hr>
                                <br></br>
                                <div class="row">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-3 text-center">
                                        <div class="table-switch" onClick={() => this.switchDisplay(0)} style={{ fontSize: "22px", color: display == 0 ? "#2273c9" : "#444444" }}>
                                            Pacijenti na čekanju
                                        </div>
                                    </div>
                                    <div class="col-md-3 text-center">
                                        <div class="table-switch" onClick={() => this.switchDisplay(1)} style={{ fontSize: "22px", color: display == 1 ? "#2273c9" : "#444444" }}>
                                            Završeni pacijenti
                                        </div>
                                    </div>
                                    <div class="col-md-3"></div>
                                </div>
                                <div class="row" style={{ marginTop: "50px" }}>
                                    <div class="col-md-12">
                                        <CustomTable
                                            type={display}
                                            user={this.state.user}
                                            users={users}
                                            usersDisplayed={usersDisplayed}
                                            testType={0}
                                            activePage={activePage}
                                            perPage={this.PER_PAGE}
                                            handlePageChange={this.handlePageChange.bind(this)}
                                            updateUserTest={this.updateUserTest.bind(this)}
                                            sortUsers={this.sortUsers.bind(this)}
                                            handlePDFClick={this.handlePDFClick.bind(this)}
                                            handleEditClick={this.handleEditClick.bind(this)}
                                            handlePreviewClick={this.handlePreviewClick.bind(this)}
                                        ></CustomTable>
                                    </div>
                                </div>
                                <EditPatientModal
                                    isEditModalShown={this.state.isEditModalShown}
                                    hideEditModal={this.hideEditModal}
                                    user={this.state.user}
                                    patient={this.state.modalPatient}
                                    onSendClick={this.updateUserTest}
                                />
                                <PreviewPatientModal
                                    isPreviewModalShown={this.state.isPreviewModalShown}
                                    hidePreviewModal={this.hidePreviewModal}
                                    user={this.state.user}
                                    patient={this.state.modalPatient}
                                />
                                <PDFPreviewModal 
                                    isPreviewModalShown={this.state.isPDFPreviewModalShown}
                                    hidePreviewModal={this.hidePDFPreviewModal}
                                    pdfURL={this.state.pdfModalURL}
                                />
                            </div>
                        </section>

                    </main>
                )}
            </div>
        );
    }
}
