import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';

export default class PreviewPatientModal extends Component {
  state = {}

  render() {
    return <div>
      {this.props.patient &&
        <Modal show={this.props.isPreviewModalShown} onHide={this.props.hidePreviewModal} size="lg">
          <Modal.Header>
            <Modal.Title>Pregled pacijenta: {this.props.patient.first_name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label for="first_name">Ime: <b>{this.props.patient.first_name}</b></label>
            <br></br>
            <label for="last_name">Prezime: <b>{this.props.patient.last_name}</b></label>
            <br></br>
            <label for="last_name">Status: <b>{this.props.patient.test_status == "PENDING" ? "NA ČEKANJU" : "ZAVRŠEN"}</b></label>
            <br></br>
            <label for="passport_num">Broj pasoša: <b>{this.props.patient.passport_num || "/"}</b ></label>
            <br></br>
            <label for="email">Email: <b>{this.props.patient.email || "/"}</b></label>
            <br></br>
            <label for="phone">Broj telefona: <b>{this.props.patient.phone}</b></label>
            <br></br>
            <label for="date_of_birth">Datum rođenja: <b>{this.props.patient.date_of_birth}</b></label>
            <br></br>
            <label for="date_of_birth">Datum uzimanja uzorka: <b>{this.props.patient.date_of_test}</b></label>
            <br></br>
            {
              this.props.patient.date_of_issuing_report_datetime != null &&
              <div>
                <label for="date_of_birth">Datum verifikacije: <b>{this.props.patient.date_of_issuing_report}</b></label>
                <div style={{ fontSize: "12px" }}>Ukoliko je pacijent na čekanju, datum verifikacije se odnosi na datum verifikacije prije izmjene podataka pacijenta</div>
                <br></br>
              </div>
            }
            <label for="gender">Spol: <b>{this.props.patient.gender == "Male" ? "Muško" : "Žensko"}</b></label>
            <br></br>
            <label for="test_type">Vrsta testa: <b>{this.props.patient.test_type}</b></label>
            <br></br>
            {this.props.patient.test_type == "PCR" &&
              this.props.patient.test_reason != null &&
              this.props.patient.test_reason.length > 0 &&
              <div><label for="test_reason">Razlog testiranja: <b>{this.props.patient.test_reason}</b></label> <br></br></div>
            }
            {this.props.patient.test_status == "RESOLVED" && (
              <div>
                <label for="test_type">Rezultat testa: <b>{this.props.patient.is_positive == true ? "POZITIVAN" : "NEGATIVAN"}</b></label>
                <br></br>
              </div>
            )
            }
            {this.props.patient.is_positive != null && this.props.patient.test_status == "PENDING" &&
              <p>Rezultat testa prije posljednje izmjene: <b>{this.props.patient.is_positive == true ? "POZITIVAN" : "NEGATIVAN"}</b></p>
            }
            <br></br>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.hidePreviewModal}>
              Nazad
            </Button>
          </Modal.Footer>
        </Modal>
      }
    </div>
  }


}