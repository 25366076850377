import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';

export default class PDFPreviewModal extends Component {
  state = {}

  render() {
    return <div>
      {this.props.pdfURL &&
        <Modal show={this.props.isPreviewModalShown} onHide={this.props.hidePreviewModal} size='lg'>
          <Modal.Body>
            {this.props.pdfURL &&
                <iframe
                    src={this.props.pdfURL + "#view=fitH#toolbar=0&navpanes=0"}
                    style={{ width: "100%", height: "800px" }}
                />
            }
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.hidePreviewModal}>
              Nazad
            </Button>
          </Modal.Footer>
        </Modal>
      }
    </div>
  }


}