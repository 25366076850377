import Joi from 'joi-browser';
import PropTypes from 'prop-types';
import React from 'react';
import { EMAIL_LABEL, PASSWORD_LABEL } from '../constants/form';
import Form from './form/Form';
import LoadingSpinner from './LoadingSpinner';

export default class SignInForm extends Form {
  static propTypes = {
    /**
     * Error to display if submit was a failure
     */
    error: PropTypes.object,

    /**
     * Is sign in info submitting
     */
    isSubmitting: PropTypes.bool.isRequired,
  };

  state = {
    submitPressed: false,
    data: {
      email: '',
      password: '',
    },
    errors: {},
  };

  schema = {
    email: Joi.string().required().label(EMAIL_LABEL),
    password: Joi.string().required().label(PASSWORD_LABEL),
  };

  onSubmit = (e) => {
    this.handleSubmit(e, this.props.onSubmit);
  };

  render() {
    const { error, isSubmitting } = this.props;
    return (
      <div className="container-fluid">
        <div className="form-row row" style={{ marginTop: '200px' }}>
          <div className="col-lg-4"></div>
          <div className="col-lg-4">
            <h2 className="text-center">Medical Centar CMS</h2>
            <hr></hr>
            {/* <h4 className="text-center">CMS Login</h4> */}
            <form onSubmit={this.onSubmit} className="form sign-in">
              <div className="text-center" style={{ marginBottom: '20px' }}>
                {/* <Logo /> */}
              </div>
              {this.renderInput(EMAIL_LABEL, EMAIL_LABEL, EMAIL_LABEL, isSubmitting, 'email')}
              {this.renderInput(PASSWORD_LABEL, PASSWORD_LABEL, PASSWORD_LABEL, isSubmitting, 'password')}
              {isSubmitting && <LoadingSpinner text="Prijava u toku, molimo pričekajte..." height={50} width={50} />}
              {!isSubmitting && (
                <section className="login-btn">
                  <div className="error-text big">{error.message}</div>
                  <div className="text-center">{this.renderButton('Dalje', 'sign-in', 'submit', this.state.submitPressed)}</div>
                </section>
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }
}
