import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getCurrentUser } from "../services/http/authService";

const ProtectedLabRoute = ({ path, component: Component, render, ...rest }) => {

  const hasLabId = () => {
    const user = getCurrentUser();
    if (!user || !user.lab_id) return false;
    return true;
  }

  return (
    <Route
      path={path}
      {...rest}
      render={props => {
        if (!hasLabId())
          return (
            <Redirect
              to={{ pathname: "/sign-in", state: { from: props.location } }}
            />
          );
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default ProtectedLabRoute;