import jwtDecode from 'jwt-decode';
import { TOKEN_HEADER } from '../../constants/headers';
import { BASE_API_ROUTE, TEST_ROUTE, TEST_DYNAMIC_ROUTE, BASE_API_ROUTE_2 } from '../../constants/routes';
import { deleteCall, get, post, put } from './base';

const ACTUAL_ROUTE = TEST_DYNAMIC_ROUTE;

export function createReport(data) {
  return post(ACTUAL_ROUTE + '/report/create', data, {}, true).then((res) => {
    return res;
  });
}

export function createPCRAllReport(data) {
  return post(ACTUAL_ROUTE + '/user/create-PCR-all-report', data, {}, true).then((res) => {
    return res;
  });
}

/*
  {
    "start_date": "01.01.1900. 12:34:56",
    "end_date": "12.12.2021. 12:34:56",
    "isToday": true
  }
  if isToday = false, then start and end date will be taken into account, otherwise they are not.
*/
export function createTransportReport(data) {
  return post(ACTUAL_ROUTE + '/user/create-transport-report', data, {}, true).then((res) => {
    return res;
  });
}

export function getPdfQuestionForm(userId) {
  return get(ACTUAL_ROUTE + '/user/pdf-form/' + userId).then((res) => {
    return res;
  });
}

export function getAllClinics() {
  return get(ACTUAL_ROUTE + '/lab/get-all').then((res) => {
    return res;
  });
}

export function getPendingLabUsers(clinicId) {
  return get(ACTUAL_ROUTE + '/lab/get/' + clinicId + '/PENDING').then((res) => {
    return res;
  });
}

export function getResolvedLabUsers(clinicId) {
  return get(ACTUAL_ROUTE + '/lab/get/' + clinicId + '/RESOLVED').then((res) => {
    return res;
  });
}


export function getUsersParams(pendingOrResolved, testType) {
  return get(ACTUAL_ROUTE + '/user/get/' + testType + "/" + pendingOrResolved).then((res) => {
    return res;
  });
}

export function putUserTestResult(userId, isPositive, dateOfIssuingReport) {
  return put(
    ACTUAL_ROUTE + '/lab/update-status/' + userId,
    {
      is_positive: isPositive,
      date_of_issuing_report: dateOfIssuingReport
    },
    true
  ).then((res) => {
    return res;
  });
}

export function postUser(data) {
  return post(ACTUAL_ROUTE + '/user/create', data, {}, true).then((res) => {
    return res;
  });
}

export function updateUser(data) {
  return put(ACTUAL_ROUTE + '/user/update', data, {}, true).then((res) => {
    return res;
  });
}

export function signIn(data) {
  return post(ACTUAL_ROUTE + '/auth/sign-in', {
    email: data.email,
    password: data.password,
  }).then((res) => {
    return res;
  });
}

export function signOut() {
  const jwt = localStorage.getItem(TOKEN_HEADER);
  return deleteCall(ACTUAL_ROUTE + '/auth/sign-out', {
    headers: {
      [TOKEN_HEADER]: jwt,
      'Content-Type': 'application/json',
    },
  }).then((res) => {
    return res;
  });
}

export function setJwt(jwt) {
  localStorage.setItem(TOKEN_HEADER, jwt);
}

export function getJwt() {
  const ajtem = localStorage.getItem(TOKEN_HEADER);
  if (ajtem == 'null') return null;
  return ajtem;
}

export function getCurrentUser() {
  try {
    const jwt = getJwt();
    return jwtDecode(jwt);
  } catch (err) {
    return null;
  }
}